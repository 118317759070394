import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Lines from "../lines/Lines";
import Racing from "../racing/Racing";
import Result from '../results/Result';
import { socket } from "../../io";
import Bonos from '../bonos/Bonos'
import Jackpots from "../jackpots/Jackpots";
import { Logout } from "../../utils/logout";
let prog
let run
function Dashboad() {
  const [screen, setScreen] = useState(4);
  const [time, setTime] = useState();
  const [progBar, setProgBar] = useState(false)
  const [paidJp, setPaidJp] = useState(false)
  const [jackpotToPay, setJackpotToPay] = useState({ valu: 0, type: '' });
  useEffect(() => {
      socket.on('run', (dataRun)=> {
        run= dataRun.run
      })
      //socket.on('video', (video)=> console.log(video))
    socket.on('get', (data) => {
      setScreen(value=> data && data.screen && data.screen!= value? data.screen: value)
      setTime(value => data && data.time && data.time!= value? data.time: value)
      prog = progBar
    })
    socket.on("screen", data => {
      setScreen(value=> data && data.screen && data.screen!= value? data.screen: value);
      if (data.time) {
        setTime(value => data.time!= value? data.time: value)
      }
    });
    socket.on('jackpot', (data) => {
      if (data && data.winner.jackpot.value > 0) {
        setPaidJp(true)
        setJackpotToPay(() => { return ({point: data.winner.jackpot.point,ticket: data.ticket, value: data.winner.jackpot.value, type: data.winner.jackpot.type }) })
      }
    })
  }, []);
  if (!localStorage.getItem('user')) {
    return window.location.href = '/'
  }
  switch (screen) {
    case 1:
      return (
        <div className="containerLine">
          <Lines screen={1} />
        </div>

      )
      break;
    case 2:
      return (
        <Racing />
      )
      break;
    case 3:
      return (
        <div className="containerLine" >
          <Lines screen={3} />
        </div>
      )
      break;
    case 4:
      if (run && run.factor.value>1) {
        return (
          <div className="containerLine">
            <Bonos className="position-absolute" {...run.factor} />
          </div>
        )
      } else {
        return (
          <div className="containerLine">
    
            <div className="error position-absolute w-100 text-align-center">

              <div className="fs-1">Próxima Carrera.......en {time} Seg. {!time?<button className="btn btn-link btn-sm" onClick={Logout}>Go</button>:''}</div>
            </div>
          </div>
        )
      }
      break;
    case 5:
      if(jackpotToPay.type=='general'||(jackpotToPay.point && localStorage.getItem('point') && jackpotToPay.point == localStorage.getItem('point'))){
              return (
        <div className="containerLine">
          <Jackpots {...jackpotToPay} />
        </div>
      )
      }else{
        return (
          <div className="containerLine" >
            <Lines screen={3} />
          </div>
        )
      }

      break;
    default:
      break;
  }

}


export default Dashboad