import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Bet from './components/bet/bet';
import Admin from './components/admin/Admin';
import Login from './components/users/Login';
import Pay from './components/Pay';
import Dashboad from './components/dashboard/Dashboard';
import Point from './components/admin/points/Point';
import Customer from './components/admin/customer/Customer';
import User from './components/admin/user';
import Venta from './components/bet/venta';
import Result from './components/results/Result';
import Historial from './components/bet/historial';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/pay" element={<Pay />}></Route>
        <Route path="/line" element={<Dashboad />}></Route>
        <Route path="/history" element={<Historial/>}></Route>
        <Route path="/venta" element={<Venta/>}></Route>
        <Route path="/bet" element={<Bet />}></Route>
        <Route path="/admin/" element={<Admin />}>
          <Route path='points' element={<Point />}></Route>
          <Route path='users' element={<User />}></Route>
          <Route path='customer' element={<Customer />}></Route>
        </Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/dashboard' element={<Dashboad />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//ServiceWorker.register();
