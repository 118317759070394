import React, {useState} from 'react';
import {Container, Nav, Navbar, Modal, Button} from 'react-bootstrap';
import {DoorOpen, DoorOpenFill} from 'react-bootstrap-icons'
import './admin.css'
import {BrowserRouter, Routes, Route, Link, Outlet} from 'react-router-dom'
import Point from './points/Point';
import Customer from './customer/Customer';
import User from './user';
import {Logout} from '../../utils/logout'
function Admin() {
  const [show, setShow] = useState('Home');
  if(!localStorage.getItem('user')){
    return window.location.href = '/'
  }
  const SelectComponent = (component) =>{
    setShow(()=>component)
  }
  return (
    <div className='container-fluid text-white card body-container'>
    <Navbar bg="dark" className='col-12 pl-0 ml-0'>
      <Nav>
          <button  onClick={()=>SelectComponent('Home')}>Home   |</button> 
          <button onClick={()=>SelectComponent('Customer')}>Clientes   |</button>
          <button onClick={()=>SelectComponent('Point')}>Puntos de Venta | </button>
          <button onClick={()=>SelectComponent('User')}> Nuevo Usuario</button>
      </Nav>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end text-light">
        <Navbar.Text className='text-light'>
          User Logged: <Link to="#" className='text-light'>{localStorage.getItem('user')}</Link>
        </Navbar.Text>
        <Navbar.Text className='text-light'>
      | <button className='btn bg-warning btn-sm'onClick={Logout}><DoorOpenFill/></button>
      </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>

    {show=='Point'? <Point/>:''}
    {show=='Customer'? <Customer/>:''}
    {show=='User'? <User/>:''}
    {show=='Home'? '':''}
  
  </div>
  )
}

export default Admin