import React, { useState, useEffect } from 'react';
import { Eye, EyeFill, Printer, PrinterFill } from 'react-bootstrap-icons';
import { url, headers } from '../../utils/const'
import './ticket.css'
import Modal from 'react-bootstrap/Modal'
import imgLogo from '../../assets/logo.png'

function Tickets(list) {
  const [show, setShow] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [ticketToPrint, setTicketToPrin] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [classPrint, setClassPrint] = useState('noDisplay')
  useEffect(() => {
    setTickets(()=> list && list.tickets? list.tickets.reverse(): [])
  })
  const GetDataTicket = (num) => {
    fetch(`${url}/ticket/${num}`, {
      method: 'get',
      headers: headers
    }).then(res => res.json())
      .then(data => {
        setTicketToPrin(()=> data.length>0? data[0]: undefined)
        setClassPrint(()=>'')
        //window.print()
      })
  }
  return (
    <div className='p-0 m-0 noPrint'>
      <div className='col-12 noPrint'><button className='btn form-control' onClick={handleShow}>Ver Tickets<EyeFill /></button></div>
      <Modal show={show} onHide={handleClose} size='xl' className='bg-info'>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body className=''>
            <div className='col-12 row m-0 p-0'>
              {tickets && tickets.length > 0 ? <div className='card col-12 table-responsive ml-0 pl-0 mt-1 pt-1 row justify-content-center noPrint'>
                <div className='card-header text-center fs-2 noPrint'>Reportes de Tickets</div>
                <div className='card-body noPrint'>
                  <table className='table table-bordered p-0 m-0 noPrint'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Num</th>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>Carrera</th>
                        <th>Apostado</th>
                        <th>Ganador</th>
                        <th>Monto Ganado</th>
                        <th>Factor</th>
                        <th>Jackpot</th>
                        <th>Monto Pagado</th>
                        <th>Estado</th>
                        <th>Hora Pago.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickets.map(z =>
                        <tr key={tickets.indexOf(z) + 1}>
                          <td>{tickets.indexOf(z) + 1} </td>
                          <td>{z.num} <button type='button' className='btn'><Eye onClick={() => GetDataTicket(z.num)}></Eye></button><Printer type="button" onClick={() => window.print()}></Printer></td>
                          <td>{new Date(z.date).toLocaleDateString('es-ES')}</td>
                          <td>{`${new Date(z.date).getHours().toString().padStart(2, '0')}:${new Date(z.date).getMinutes().toString().padStart(2, '0')}:${new Date(z.date).getSeconds().toString().padStart(2, '0')}`}</td>
                          <td>{z.run}</td>
                          <td>{z.betted.toLocaleString('en-EN')}</td>
                          <td>{z.winner.state == true ? 'Ganador' : ''}</td>
                          <td>{z.winner.state == true ? parseInt(z.winner.amountWinner).toLocaleString('en-EN') : ''}</td>
                          <td>{z.winner.factor}</td>
                          <td>{z.winner.jackpot.value > 0 ? parseInt(z.winner.jackpot.value).toLocaleString('en-EN') : ''}</td>
                          <td>{z.winner.state == true ? parseInt(z.winner.amountToPay).toLocaleString('en-EN') : ''}</td>
                          <td>{z.winner.paid == true ? 'Pagado' : ''}</td>
                          <td>{z.winner.paid == true ? `${new Date(z.hourPaid).getHours().toString().padStart(2, '0')}:${new Date(z.hourPaid).getMinutes().toString().padStart(2, '0')}:${new Date(z.hourPaid).getSeconds().toString().padStart(2, '0')}` : ''}</td>
                        </tr>)}
                    </tbody>
                  </table>
                </div>
              </div> : <div className='noPrint'>No Hay Tickets</div>}
              {<div className={classPrint}>
              <div>
              <div className='p-0 m-0'>
              <div className='col-12 d-flex justify-content-center p-0 m-0'>
                <div className='divLogoPrint align-midle'><img className='imgLogoPrint ' src={imgLogo} /></div>
              </div>
              <h6>{ticketToPrint ? ticketToPrint.point.name : ''}</h6>
              <h6>Ticket No.: {ticketToPrint ? ticketToPrint.num : ''}</h6>
              <h6>Carrera: {ticketToPrint ? ticketToPrint.run.code : ''}</h6>
              <h6>Fecha: {ticketToPrint ? `${new Date(ticketToPrint.date).toLocaleDateString('es-ES')} ${(new Date(ticketToPrint.date).getHours()).toString().padStart(2, '0')}:${(new Date(ticketToPrint.date).getMinutes()).toString().padStart(2, '0')}:${(new Date(ticketToPrint.date).getSeconds()).toString().padStart(2, '0')}` : ''}</h6>
            </div>
              <table className='table table-sm col-12 p-0 m-0 lh-1'>
                <thead>
                  <tr>
                    <th>Jugadas</th>
                    <th>Apostado</th>
                    <th>Paga</th>
                    <th>Ganacia</th>
                  </tr>
                </thead>
                <tbody>
                  {ticketToPrint ? ticketToPrint.betted.map(z => <tr key={z.code} className="p-0 m-0">
                    <td className='p-0 m-0'>{z.dog}</td>
                    <td className='p-0 m-0'>{parseInt(z.betted).toLocaleString('en-EN', { maximumFractionDigits: 1 })}</td>
                    <td className='p-0 m-0'>{parseFloat(z.paying).toLocaleString('en-EN', { maximumFractionDigits: 1 })}</td>
                    <td className='p-0 m-0'>{parseInt(z.win).toLocaleString('en-EN', { maximumFractionDigits: 1 })}</td>
                  </tr>) : ''}
                </tbody>
              </table>
              <h3>Total Apostado:  {ticketToPrint ? ticketToPrint.amount : ''}</h3>
              <div>  <h3>Copia</h3></div>
            </div>
              </div>}
            </div>
          </Modal.Body>
          <Modal.Footer className='noPrint'>
            <button onClick={handleClose}>
              Close
            </button>
            <button type='button'>
              Print
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  ) 
}


export default Tickets