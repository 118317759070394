import React, { Component } from 'react';
import './racing.css';
import { socket } from '../../io';


class Racing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoSrc: '',
    };
    this.videoRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    socket.on('video', (data) => {
      if (data.video) {
        console.log(data.video)
        this.setState({ videoSrc: `../../convertido/${data.video}`});
      }
    });
  }

  componentWillUnmount() {
   // socket.off('video');
  }

  componentDidUpdate() {
    this.renderVideoOnCanvas();
  }

  handleResize = () => {
    const canvas = this.canvasRef.current;
    if (canvas) {
      canvas.width = window.innerWidth;
      // Puedes ajustar la altura según tus necesidades
      canvas.height = window.innerHeight;
    }
  };

  renderVideoOnCanvas() {
    const video = this.videoRef.current;
    const canvas = this.canvasRef.current;

    if (video && canvas) {
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    }
  }

  render() {
    return (
      <div className="player-wrapper">
         <video
          className="react-player"
          width="100%"
          src={this.state.videoSrc}
          ref={this.videoRef}
          muted
          autoPlay
        ></video>
      </div>
    );
  }
}

export default Racing;
