
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoImg from '../../img/Imagen3.png'

export const ReportPDF = (report, date) => {
    const doc = new jsPDF('landscape','mm'); // Configura la página en orientación horizontal
const fontsize = 8;
    // Estilo para el encabezado
    doc.setFontSize(18);
    //doc.setFillColor(220, 220, 220); // Color de fondo suave
    doc.text('Corriendogs', 148, 10, {align: 'center'});
    doc.setFontSize(12);
    doc.text('Reporte de Venta', 148, 15, {align: 'center'});
    doc.text(date.init!==''? 'Del '+(new Date(`${date.init}T00:00:00`)).toLocaleDateString('es-ES')+ 'Al '+(new Date(`${date.end}T00:00:00`)).toLocaleDateString('es-ES'): `${(new Date()).toLocaleDateString('es-ES')} ${new Date().getHours().toString().padStart(2,'0')}:${new Date().getMinutes().toString().padStart(2,'0')}:${new Date().getSeconds().toString().padStart(2,'0')}`, 148, 20, {align: 'center'});
   // doc.addImage(logoImg, 'PNG', 10, 10, 50, 50);
    // Datos para la tabla
    const tableData1 = ['Num', 'Puntos', 'Socios', 'Ciudad', 'Tickets', 'Apostado', 'Pagado', 'Jackpot', 'Beneficio', 'Balance (%)']
    const tableData =  report.map(z =>[
        report.indexOf(z)+1,
        z.name,
        z.customer.name,
      //  z.address.street,
        z.address.city,
        z._static && z._static.amountBetted!=null? z._static.counterTicket:'',
        z._static && z._static.amountBetted!=null? parseInt(z._static.amountBetted).toLocaleString('en-EN'):'',
        z._static && z._static.amountPaid!=null? parseInt(z._static.amountPaid).toLocaleString('en-EN'):'',
        z._static && z._static.jackpotPaid!=null? parseInt(z._static.jackpotPaid).toLocaleString('en-EN'):'',
        z._static && z._static.dif!=null? parseInt(z._static.dif).toLocaleString('en-EN'):'',
        `${z._static && z._static.rate!=null?parseFloat( z._static.rate).toFixed(2):''}%`
    ]).concat([['','', '', 'Total', report.length>0? parseInt(report.reduce((acum, current)=> current._static.counterTicket +acum,0)).toLocaleString('en-EN'):0, 
    report.length>0? parseInt(report.reduce((acum, current)=> current._static.amountBetted +acum,0)).toLocaleString('en-EN'):0,
    report.length>0? parseInt(report.reduce((acum, current)=> current._static.amountPaid +acum,0)).toLocaleString('en-EN'):0,
    report.length>0? parseInt(report.reduce((acum, current)=> current._static.jackpotPaid +acum,0)).toLocaleString('en-EN'):0, 
    report.length>0? parseInt(report.reduce((acum, current)=> current._static.dif +acum,0)).toLocaleString('en-EN'):0,
    `${report.length>0? (report.reduce((acum, current)=> current._static.dif +acum,0)*100/report.reduce((acum, current)=> current._static.amountBetted +acum,0)).toFixed(2):0}%`]])

    // Configuración de la tabla
    const tableConfig = {
      startY: 25,
      margin: { top: 1 },
    };

    doc.autoTable({
      head: [tableData1],
      body: tableData,
      startY: 25,
      theme: 'grid', // Sin color y con borde
      styles:{
        halign: 'left',
        fontSize:9
      },
      headStyles:{
        fillColor: [150, 150, 150], // Color de fondo del encabezado
        textColor: 0, // Color del texto
        fontStyle: 'bold',
        fontSize: 9
      },
      columnStyles:{
        0:{halign: 'center', fontSize: fontsize, fontStyle: 'courier', cellWidth:10},
        1:{halign: 'left', fontSize: fontsize,  fontStyle: 'courier', cellWidth:50},
        2:{halign: 'left', fontSize: fontsize, cellWidth:50},
        3:{halign: 'left', fontSize: fontsize, cellWidth:50},
        4:{halign: 'center', fontSize: fontsize, cellWidth:20},
        5:{halign: 'right', fontSize: fontsize, cellWidth:20},
        6:{halign: 'right', fontSize: fontsize, cellWidth:20},
        7:{halign: 'right', fontSize: fontsize, cellWidth:20},
        8:{halign: 'right', fontSize: fontsize, cellWidth:20},
        9:{halign: 'right', fontSize: fontsize, cellWidth:20},
        10:{halign: 'right', fontSize: fontsize, cellWidth:20},
      }
    });

    doc.output('dataurlnewwindow',{filename:'miPDF.pdf'});
  };

  
