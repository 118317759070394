import React, { useEffect, useState } from 'react'
import './Login.css'
import { headers, url } from '../../utils/const'
function Login() {
    const [userName, setUser] = useState();
    const [password, setPassword] = useState();
    const [level, setLevel] = useState('1')
    /*  <ReactWhatsapp number="+18293338488" message="Hello World!!!"  value="What"/>*/

    useEffect(() => {
        console.log(document.cookie)
        if (localStorage.getItem('sessionId')) {
            if (localStorage.getItem('level') == '1' || localStorage.getItem('level') == '2') window.location.href = '/admin'
            if (localStorage.getItem('level') == '4') window.location.href = '/bet';
            if (localStorage.getItem('level') == '3') window.location.href = '/line';
            if (localStorage.getItem('level') == '5') window.location.href = '/line';
        }

    }, [])
    /**detectar cambio en input */
    const ToChange = (e) => {
        if (e.target.name == 'userName') setUser(e.target.value)
        if (e.target.name == 'password') setPassword(e.target.value)
        if (e.target.name == 'level') setLevel(e.target.value)
    }

    /**Login */
    const ToLogin = () => {
        fetch(`${url}/login`, {
            method: 'post',
            headers: headers,
            body: JSON.stringify({ username:userName, password, level})
        }).then(res => res.json()).then(data => {
            console.log('Token:',data)
          if (data && data.user && data.name && data.level) {
                localStorage.setItem('sessionId', data.sessionId); 
                localStorage.setItem('user', data.user);
                localStorage.setItem('name', data.name);
                localStorage.setItem('level', data.level);
                localStorage.setItem('point', data.point);
                document.cookie =`sessionId=${data.sessionId} path=/`
                if (localStorage.getItem('user') || localStorage.getItem('sessionId')) window.location.href = '/';
            } else {
            }
        }).catch(error => console.log(error))
    }
    return (
        <div className='container justify-content-center row'>

            <div className='row col-6 card'>
                <div className='card-header' ><label className='etiquet'>Login</label></div>
                <div className='card-body'>
                    <div className='for-group col-6'>
                        <div className='col-12'><label>Usuario</label></div>
                        <div className='col-12'><input type="text" name='userName' className='form-control' value={userName} onChange={ToChange} /></div>
                    </div>
                    <div className='for-group col-6'>
                        <div className='col-12'><label>Password</label></div>
                        <div className='col-12'><input type="password" name='password' className='form-control' value={password} onChange={ToChange} /></div>
                    </div>
                    <div className='for-group col-6'>
                        <div className='col-12'><label>Seleccione el Rol</label></div>
                        <div className='col-12'><select className='form-select' name='level' value={level} onChange={ToChange}>
                            <option value="1">Admin</option>
                            <option value="2">Consorcio</option>
                            <option value="3">Soporte Tecnico</option>
                            <option value="5">Jugador</option>
                            <option value="4">Punto de Venta</option>
                            
                        </select></div>
                    </div>
                </div>
                <div className='card-footer row col-12'>
                    <div className='col-8'><button>Forgot Password</button></div>
                    <div className='col-5 log'><a onClick={ToLogin}>Login</a></div>
                </div>
            </div>
        </div>
    )
}

export default Login