import React, { useEffect, useState } from 'react'
import { headers, url } from '../../utils/const';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { DoorOpen, DoorOpenFill } from 'react-bootstrap-icons';
import './historial.css'

function Historial() {
    const [historal, setHistorial] = useState([]);
    useEffect(() => {
        fetch(`${url}/run`, {
            method: 'get',
            headers: headers
        }).then(res => res.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setHistorial(()=> data)
                }
            })
    }, [])
    return (
        <div>
        <header>
        <nav>
          <div className="nvegador">
            <Link className='vol venta' to="/bet">VENTA</Link>
            <Link to="/line" className='vol lin'>LINEA</Link>
            <Link className='vol res' to='/history'>RESULTADOS</Link>
            <Link className='vol volvent' to='/venta'>VOLUMEN DE VENTAS</Link>
            <button className="boton_menu bg-danger" onClick={() => localStorage.clear()}><DoorOpenFill /></button>
          </div>
        </nav>
      </header>
        
        <div className='historialClass pt-3'>
        <div className='justify-content-end pt-3 pb-3'>
        </div>
        <div className='justify-content-end pt-3 pb-3'>
        </div>
        <div className=' table-responsive'>
        <div className=''>
            <table className='table'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Carrera</th>
                        <th>Fecha</th>
                        <th>1RO</th>
                        <th>2DO</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        historal.map(z=> <tr>
                        <td>{historal.indexOf(z)+1}</td>
                        <td>{z.num}</td>
                        <td>{new Date(z.date).toLocaleDateString('es-ES')}</td>
                        <td>{z.dogWinners? z.dogWinners.first : ''}</td>
                        <td>{z.dogWinners? z.dogWinners.second : ''}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
        </div>
        </div>
        </div>
    )
}

export default Historial