import React, { useState, useEffect, useRef } from 'react';
import { Link, json } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import { DoorOpen, DoorOpenFill } from 'react-bootstrap-icons'
import { addSeconds, intervalToDuration } from 'date-fns'
import './bet.css';
import imgPrint from '../../assets/venta/imprimir.png';
import imgLogo from '../../assets/logo.png';
import imgDel from '../../assets/venta/ELIMINAR.png';
import imgRepeat from '../../assets/venta/btnrepetir.png';
import btnDirect from '../../assets/venta/btndirecto.png';
import all from '../../assets/venta/contodos.png';
import imgEnter from '../../assets/venta/enter2.png';
import btnAhead from '../../assets/venta/btnpalante.png';
import { socket } from '../../io';
import { CalcularApostado } from '../../utils/Calculo-apostado';
import { headers, url } from '../../utils/const'
import Dashboad from '../dashboard/Dashboard';
import { Logout } from '../../utils/logout';
let betting = []
let bettingShow =[]
let print = undefined;
function Bet() {
  const [firstBet, setFirstBest] = useState('');
  const [secondBet, setSecondBet] = useState('');
  const [time, setTime] = useState('');
  const [hour, setHour] = useState('');
  const [nextRun, setNextRun] = useState('');
  const [run, setRun] = useState('')
  const [betted, setBetted] = useState(0);
  const [selected, setSelected] = useState({ p11: '', p12: '', p13: '', p14: '', p15: '', p16: '', p21: '', p22: '', p23: '', p24: '', p25: '', p26: '' })
  const [total, setTotal] = useState(0);
  const [inTeclado, setInTeclado] = useState('');
  const [winner, setWinner] = useState('');
  const [show, setShow] = useState('')
  const [active, setActive] = useState(false);
  const [newTicketList, setNewTicketList] = useState([])
  const [showTextArea, setShowTextArea] = useState(false)
  const [PrintSt, setPrintSt] = useState()
  let newTicket =[]
  let contentPrint = 'print';
  /** Datos de la Carrera actual para cual sera la apuesta */
  useEffect(() => {
    if (!localStorage.getItem('user')) return window.location.href = '/';
    socket.on('screen', (data) => {
      setTime((value) => {
        if (value && value <= 10) {
          setSelected({ p11: '', p12: '', p13: '', p14: '', p15: '', p16: '', p21: '', p22: '', p23: '', p24: '', p25: '', p26: '' })
          betting = []
          setTotal(() => 0)
          setFirstBest('');
          setSecondBet('');
          setBetted(0);
          setActive(false);
        }
        return data.time
      })

      const hr = `${(new Date().getHours().toString()).padStart(2, '0')}:${(new Date().getMinutes()).toString().padStart(2, '0')}:${(new Date().getSeconds()).toString().padStart(2, '0')}`;
      const lasthours = addSeconds(new Date(), data.time)
      setHour(() => hr);
      setNextRun(() => `${lasthours.getHours().toString().padStart(2, '0')}:${(lasthours.getMinutes().toString()).padStart(2, '0')}:${(lasthours.getSeconds()).toString().padStart(2, '0')}`)
    })
    if (PrintSt && PrintSt.num) {
      if (localStorage.getItem('level') !== '4') return window.location.href = '/';
      window.print()
      setPrintSt(undefined)
      print = undefined;


    }
     /**Socket.Io */
  socket.on('run', (data) => {
    setRun(data.run)
  },[PrintSt])
 /**Notifica que el punto esta activo */
  // socket.on('active', (data)=>{
  //   socket.emit('active', {point:localStorage.getItem('point')})
  // })
  })
  if (!localStorage.getItem('user')) {
    return window.location.href = '/'
  }

  const addValue = (place, num) => {
    if (place === 1) {
      if (num === 1) {
        setSelected((selected) => {
          if (selected.p11 === 'betted') {
            return { ...selected, p11: '' }
          } else {
            return { ...selected, p11: 'betted' }
          }
        })
      }
      if (num === 2) {
        setSelected((selected) => {
          if (selected.p12 === 'betted') {
            return { ...selected, p12: '' }
          } else {
            return { ...selected, p12: 'betted' }
          }
        })
      }
      if (num === 3) {
        setSelected((selected) => {
          if (selected.p13 === 'betted') {
            return { ...selected, p13: 'numb3' }
          } else {
            return { ...selected, p13: 'betted' }
          }
        })
      }
      if (num === 4) {
        setSelected((selected) => {
          if (selected.p14 === 'betted') {
            return { ...selected, p14: '' }
          } else {
            return { ...selected, p14: 'betted' }
          }
        })
      }
      if (num === 5) {
        setSelected((selected) => {
          if (selected.p15 === 'betted') {
            return { ...selected, p15: '' }
          } else {
            return { ...selected, p15: 'betted' }
          }
        })
      }
      if (num === 6) {
        setSelected((selected) => {
          if (selected.p16 === 'betted') {
            return { ...selected, p16: 'numb6' }
          } else {
            return { ...selected, p16: 'betted' }
          }
        })
      }
      setFirstBest((value) => {
        if (value.includes(num)) {
          const numBetted = value.split('').filter(item => item != num).join('');
          return numBetted
        } else {
          return value + num
        }

      })
    } else if (place === 2) {
      if (num === 1) {
        setSelected((selected) => {
          if (selected.p21 === 'betted') {
            return { ...selected, p21: '' }
          } else {
            return { ...selected, p21: 'betted' }
          }
        })
      }
      if (num === 2) {
        setSelected((selected) => {
          if (selected.p22 === 'betted') {
            return { ...selected, p22: '' }
          } else {
            return { ...selected, p22: 'betted' }
          }
        })
      }
      if (num === 3) {
        setSelected((selected) => {
          if (selected.p23 === 'betted') {
            return { ...selected, p23: 'numb3' }
          } else {
            return { ...selected, p23: 'betted' }
          }
        })
      }
      if (num === 4) {
        setSelected((selected) => {
          if (selected.p24 === 'betted') {
            return { ...selected, p24: '' }
          } else {
            return { ...selected, p24: 'betted' }
          }
        })
      }
      if (num === 5) {
        setSelected((selected) => {
          if (selected.p25 === 'betted') {
            return { ...selected, p25: '' }
          } else {
            return { ...selected, p25: 'betted' }
          }
        })
      }
      if (num === 6) {
        setSelected((selected) => {
          if (selected.p26 === 'betted') {
            return { ...selected, p26: 'numb6' }
          } else {
            return { ...selected, p26: 'betted' }
          }
        })
      }
      setSecondBet((value) => {
        if (value.includes(num)) {
          const numBetted = value.split('').filter(item => item != num).join('');
          return numBetted
        } else {
          return value + num
        }
      })
    }

  }
  /**Agregar a jugada */
  const AddBetting = (type) => {
    setShowTextArea(false)
    const bodyBet = {
      dog: { firt: firstBet, second: secondBet },
      amount: betted,
      type: type,
      run: run
    }
    if (bodyBet.amount <= 1000 && bodyBet.amount > 0) {
      if (bodyBet.amount) {
        if (betting.some(item => item.type == bodyBet.type && item.dog.firt == bodyBet.dog.firt && item.dog.second == bodyBet.dog.second)) {
          alert('Jugada Repetida')
        } else {

         // if(bodyBet.dog.firt.length !=1 || bodyBet.dog.second.length >1) return alert('Jugada No Valida: ' + bodyBet.dog.firt+ ' ' +bodyBet.dog.second)
        const bettedPlusAMount = CalcularApostado(bodyBet)
         setTotal((total) => bettedPlusAMount.amount + total) 
          betting.push(bodyBet)
         bettedPlusAMount.bodyBet.forEach(item => bettingShow.push(item))
          setFirstBest('');
          setSecondBet('');
          setSelected({ p11: '', p12: '', p13: '', p14: '', p15: '', p16: '', p21: '', p22: '', p23: '', p24: '', p25: '', p26: '' })
          setBetted(0)
          setNewTicketList([]);
          const bettingShowOnly = [];
          bettingShow.forEach(item =>{
            if(!bettingShowOnly.some(y => y.dog.firt==item.dog.fir && y.dog.second== item.dog.second)) bettingShowOnly.push(item)
          })
          bettingShowOnly.forEach((z, index) => {
            let win
            let pay
            let dog
            if (z.dog.second == '') {             
              pay = z.run.line.d[`p${z.dog.firt}`]
              win = pay * z.amount
              dog = `G-${z.dog.firt}`

              newTicket.push({
                amount: z.amount,
                pay:pay.toFixed(2),
                dog,
                win:parseInt(win).toLocaleString('en-EN')
              }
              )
            } else {

              pay = z.run.line[`p${z.dog.firt}${z.dog.second}`]
              win = pay * z.amount
              dog = `P-${z.dog.firt}-${z.dog.second}`
              newTicket.push({
                amount: z.amount,
                pay:parseFloat(pay).toFixed(2),
                dog,
                win:parseInt(win).toLocaleString('en-EN')
              })
             /* if (z.type.includes('ATRA')) {
                dog = `P-${z.dog.second}-${z.dog.firt}`
                newTicket.push({
                  amount: z.amount,
                  pay:parseFloat(pay).toFixed(2),
                  dog,
                  win:parseInt(win).toLocaleString('en-EN') 
                })
              }*/
            }
          })
          setNewTicketList(newTicket)
        }
      } else {
        setBetted(0)
        alert('Debes Seleccionar el monto apostado...')
      }
      setActive(true)
    } else {
      alert('Monto No Valido')
    }
  }
  /**Seleccionar el tipo de apuesta  DIRECTO, PATRA Y PALANTE, CON TODO*/

  /**Eliminar */
  const Eliminar = () => {
    if (inTeclado != '') {
      setShow(true)
    } else {
      setSelected({ p11: '', p12: '', p13: '', p14: '', p15: '', p16: '', p21: '', p22: '', p23: '', p24: '', p25: '', p26: '' })
      betting = []
      setTotal(() => 0)
      setFirstBest('');
      setSecondBet('');
      setBetted(0);
      setNewTicketList([])
      newTicket =[]
      bettingShow =[]
      
    }
  }

  /**Eliminar Ticket */
  const EliminarTicket = () => {
    setShowTextArea(true)
    fetch(`${url}/ticket/pay`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ ticket: inTeclado, action: 'Delete' })
    }).then(res => res.json())
      .then(data => {
        if (typeof (data) != 'string' && !data.msg) {
          setWinner('')
          setWinner(() => data.amountToPay)
        } else if (typeof (data) == 'string') {
          setWinner('')
          setWinner(() => data)
        } else if (data.msg && data.msg.includes('agado')) {
          print = data.ticket;
          setWinner(data.msg)

        }
      })
  }
  /**Realizar apuesta */
  const ToPrint = () => {

    if (localStorage.getItem('level') !== '4') return window.location.href = '/';   
    if (active === true) {
      setActive(false)
      fetch(`${url}/ticket`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(betting)

      }).then(res => res.json())
        .then(data => {
          if ((typeof (data) === 'string' && data.includes('Error')) || (data.message && data.message.includes('allida'))) {
            alert('Ha Cometido Un Error Al Digitar La Jugada Favor Elimine y Vuelva a Digitar')
            setBetted(()=>[])
          } else {
            setNewTicketList([]);
            setSelected({ p11: '', p12: '', p13: '', p14: '', p15: '', p16: '', p21: '', p22: '', p23: '', p24: '', p25: '', p26: '' })
            betting = []
            bettingShow = []
            setTotal(0);
            setBetted(0);
            contentPrint = ''
            print = data;
            setPrintSt(data)
          }

        }).catch(error => {
          console.log('Error:', error)
        })
    }
  }

  /**Entrada por teclado */
  const InputTeclado = (num) => {
    setInTeclado((value) => `${value}${num}`);
  }
  /**Agregar a monto de apuesta */
  const AddBetAmount = (value) => {
    if (betted + value <= 1000) {
      setBetted((betted) => parseFloat(betted) + parseFloat(value))
    } else {
      alert('Apuesta Maxima Superada')
    }
  }
  /**Eliminar el ultimo caracter */
  const Back = () => {
    setInTeclado((value) => value.substring(0, value.length - 1))
  }
  /**Enter Para Pago */
  const EnterPay = (action) => {
setShowTextArea(true)
    fetch(`${url}/ticket/pay`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ ticket: inTeclado, action })
    }).then(res => res.json())
      .then(data => {
        if (typeof (data) != 'string' && !data.msg) {
          setWinner('')
          setWinner(() => parseInt(data.amountToPay))
        } else if (typeof (data) == 'string') {
          setWinner('')
          setWinner(() => data)
        } else if (data.msg && data.msg.includes('agado')) {
          print = data.ticket;
          setPrintSt(()=> data.ticket)
          setWinner(data.msg)

        }else{
          if(data.hasOwnProperty('msg') && data.msg.includes('petid')){
            setWinner(data.msg)
            print = data.ticket;
            setPrintSt(()=> data.ticket)
            setInTeclado('')
          }
        }
      })
  }

/**evento keypress */
window.addEventListener('keypress', (event)=>{
})
  /**Mostrar  */
  if (show === 'line') {
    return (
      <Dashboad />
    )
  } else {
    if (time) {
      return (
        <div className="container-fluid">


          <div className='yPrint print text-center'>
            <div className='p-0 m-0'>
              {
                print && print.winner ? '' : print && print.codeRun? <div className='p-0 m-0'>
                  <div className='col-12 d-flex justify-content-center p-0 m-0'>
                  
                    <div className='divLogoPrint align-midle'><img className='imgLogoPrint ' src={imgLogo} /></div>
                    
                  </div>
                  <h6>{print ? print.pv : ''}</h6>
                  <h6>Ticket No.: {print ? print.num : ''}</h6>
                  <h6>Carrera: {print ? print.codeRun : ''}</h6>
                  <h6>Fecha: {print ? `${new Date(print.date).toLocaleDateString('es-ES')} ${(new Date(print.date).getHours()).toString().padStart(2, '0')}:${(new Date(print.date).getMinutes()).toString().padStart(2, '0')}:${(new Date(print.date).getSeconds()).toString().padStart(2, '0')}` : ''}</h6>
                </div>:
                <div className='p-0 m-0'>
                  <div className='col-12 d-flex justify-content-center p-0 m-0'>
                    <div className='divLogoPrint align-midle'><img className='imgLogoPrint ' src={imgLogo} /></div>
                  </div>
                  <h6>{new Date().getHours()}</h6>
                  <h6>Ticket No.: {new Date().getMinutes()}</h6>
                  <h6>Carrera: {new Date().getSeconds()}</h6>
                  <h6>Fecha: {new Date().toLocaleDateString('es-ES')}</h6>
                </div>}
              {print && print.winner ? <div className='text-center'>
                <h3>Monto Ganador:</h3>
                <h3>{parseInt(print.winner.amountToPay).toLocaleString('en-EN', { maximumFractionDigits: 0 })}</h3>
                <div>.</div>
              </div> :
                <div>

                  <table className='table table-sm col-12 p-0 m-0 lh-1'>
                    <thead>
                      <tr>
                        <th>Jugadas</th>
                        <th>Apostado</th>
                        <th>Paga</th>
                        <th>Ganacia</th>
                      </tr>
                    </thead>
                    <tbody>
                      {print ? print.betted.map(z => <tr key={z.code} className="p-0 m-0">
                        <td className='p-0 m-0'>{z.dog}</td>
                        <td className='p-0 m-0'>{parseInt(z.betted).toLocaleString('en-EN', { maximumFractionDigits: 1 })}</td>
                        <td className='p-0 m-0'>{parseFloat(z.paying).toLocaleString('en-EN', { maximumFractionDigits: 1 })}</td>
                        <td className='p-0 m-0'>{parseInt(z.win).toLocaleString('en-EN', { maximumFractionDigits: 1 })}</td>
                      </tr>) : ''}
                    </tbody>
                  </table>
                  <h3>Total Apostado:  {print ? print.amount : ''}</h3>
                  <div>.</div>
                </div>
              }
            </div>
          </div>

          <div className="noPrint">

            <header>
              <nav className='bg-primary row col-12 p-0 m-0'>

                <div className="pt-1 mt-1">
                  <Link className='vol venta' to="/bet">VENTA</Link>
                  <Link to="/line" className='vol lin'>LINEA</Link>
                  <Link className='vol res' to='/history'>RESULTADOS</Link>
                  <Link className='vol volvent' to='/venta'>VOLUMEN DE VENTAS</Link>
                  <button className="boton_menu bg-danger" onClick={Logout}><DoorOpenFill /></button> 
                </div>
              </nav>
            </header>
            <div className='regla'>
              <Modal show={show} onHide={!show} size='sm' className=''>
                <Modal.Header closeButton>
                </Modal.Header>
                <form>
                  <Modal.Body>
                    <h3>Desea Anular Tickey {inTeclado}?</h3>
                  </Modal.Body>
                  <Modal.Footer>
                    <button onClick={() => setShow(false)}>
                      Close
                    </button>
                    <button type='button' onClick={EliminarTicket}>
                      Aceptar
                    </button>
                  </Modal.Footer>
                </form>
              </Modal>
              <div className='reg1'><div className="carrera_new"><p className="text_new">Carrera</p></div></div>
              <div className='reg2'></div>
              <div className='reg3'><p className="palabra_inicia">Inicia</p></div>
              <div className='reg4'><div className="corriendogs"><p className="corr">Corrien</p><p className="dogis">DOGS</p></div></div>
              <div className='reg8'><div className="actual sub"><p className="text_actual">Actual</p></div></div>
              <div className='reg9'><div className="segundos sub"><p className="text_segundos">Segundos</p></div></div>
              <div className='reg10'><div className="new_carrera"><p className="text_new_carr">{run ? run.code : ''}</p></div></div>
              <div className='reg11'></div>
              <div className='reg12'><div className="hora sub"><p className="hora_inicio">{nextRun}</p></div></div>
              {/* <div className='reg13'></div> */}
              {/* <div className='reg14'></div> */}
              {/* <div className='reg15'></div> */}
              {/* <div className='reg16'></div> */}
              <div className='reg17'><div className="hora_actual sub"><p className="text_hora_actual">{hour}</p></div></div>
              <div className='reg18'><div className="cronometro sub"><p className="text_cronometro">{time}</p></div></div>
              <div className='reg19'></div>
              <div className='reg20'><div className='amount'><input type="number" className='amount parcial' value={betted} /></div></div>
              <div className='reg21'><div className='amount'><input type="number" className='amount total' value={total} /></div></div>
              <div className='reg22'><div className='amount'><input type="number" className='amount numero_ticket' placeholder='Ticket actual' value="" /></div></div>
              {/* <div className='reg23'></div> */}
              <div className='reg24'></div>
              <div className='reg25'></div>
              <div className='reg26'><div className='amount caractr'><input type="number" className='amount entrada' placeholder='Entre ticket' value={inTeclado} /></div></div>
              {/* <div className='reg27'>27</div> */}
              <div className='reg28'><div className="primero"><p>1<sup className="indice">0</sup></p></div></div>
              <div className='reg29'><div className={'buttons numb1' + ' ' + selected.p11} onClick={() => addValue(1, 1)}><button className="numb">1</button></div></div>
              <div className='reg30'><div className={'buttons numb2' + ' ' + selected.p12} onClick={() => addValue(1, 2)}><button className="numb">2</button></div></div>
              <div className='reg31'><div className={'buttons numb3' + ' ' + selected.p13} onClick={() => addValue(1, 3)}><button className="numb">3</button></div></div>
              <div className='reg32'><div className={'buttons numb4' + ' ' + selected.p14} onClick={() => addValue(1, 4)}><button className="numb">4</button></div></div>
              <div className='reg33'><div className={'buttons numb5' + ' ' + selected.p15} onClick={() => addValue(1, 5)}><button className="numb">5</button></div></div>
              <div className='reg34'><div className={'buttons numb6' + ' ' + selected.p16} onClick={() => addValue(1, 6)}><button className="numb">6</button></div></div>
              <div className='reg35'>
                <div className='teclado'>
                  <button className='per' onClick={() => InputTeclado(1)}>1</button>
                  <button className='sdo' onClick={() => InputTeclado(2)}>2</button>
                  <button className='tro' onClick={() => InputTeclado(3)}>3</button>
                  <button className='cto' onClick={() => InputTeclado(4)}>4</button>
                  <button className='qto' onClick={() => InputTeclado(5)}>5</button>
                  <button className='xto' onClick={() => InputTeclado(6)}>6</button>
                  <button className='smo' onClick={() => InputTeclado(7)}>7</button>
                  <button className='ovo' onClick={() => InputTeclado(8)}>8</button>
                  <button className='nno' onClick={() => InputTeclado(9)}>9</button>
                  <button className='backsp' onClick={Back}>←</button>
                  <button className='cero' onClick={() => InputTeclado(0)}>0</button>
                  <button className='del' onClick={() => { setWinner(''); setInTeclado(() => '') }}>C</button>
                </div>
              </div>
              {/* <div className='reg36'>3611</div> */}
              <div className='reg37'><div className="segundo"><p>2<sup className="indice2">0</sup></p></div></div>
              <div className='reg38'><div className={'buttons_1 numb1' + ' ' + selected.p21} onClick={() => addValue(2, 1)}><button className="numb_11">1</button></div></div>
              <div className='reg39'><div className={'buttons_1 numb2' + ' ' + selected.p22} onClick={() => addValue(2, 2)}><button className="numb_11">2</button></div></div>
              <div className='reg40'><div className={'buttons_1 numb3' + ' ' + selected.p23} onClick={() => addValue(2, 3)}><button className="numb_11">3</button></div></div>
              <div className='reg41'><div className={'buttons_1 numb4' + ' ' + selected.p24} onClick={() => addValue(2, 4)}><button className="numb_11">4</button></div></div>
              <div className='reg42'><div className={'buttons_1 numb5' + ' ' + selected.p25} onClick={() => addValue(2, 5)}><button className="numb_11">5</button></div></div>
              <div className='reg43'><div className={'buttons_1 numb6' + ' ' + selected.p26} onClick={() => addValue(2, 6)}><button className="numb_11">6</button></div></div>
              {/* <div className='reg44'></div>
                    <div className='reg45'>45</div> */}
              <div className='reg46'></div>
              <div className='reg47'><div className="pesos" onClick={() => AddBetAmount(25)}><button className="cash">$ 25.00</button></div></div>
              <div className='reg48'><div className="pesos" onClick={() => AddBetAmount(50)}><button className="cash">$ 50.00</button></div></div>
              <div className='reg49'><div className="pesos" onClick={() => AddBetAmount(100)}><button className="cash">$ 100.00</button></div></div>
              <div className='reg50'><div className="pesos" onClick={() => AddBetAmount(200)}><button className="cash doci">$ 200.00</button></div></div>
              <div className='reg51'></div>
              <div className='reg52'><div className="entr"><input className='intro' type="image" src={imgEnter} onClick={() => EnterPay('pay')} /></div></div>
              <div className='reg53'>
                {
                 showTextArea ? <div><textarea className='textArea' rows={4} placeholder='' value={winner} readOnly></textarea> </div> :
                    <div className='div-bet'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>Jugada</th>
                            <th>Apostado</th>
                            <th>Gana</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            newTicketList.map((z, index) => 
                            <tr key={index}>
                              <td>{z.dog}</td>
                              <td>{z.amount}</td>
                              <td>{z.pay}</td>
                              <td>{z.win}</td>
                            </tr>)
                          }
                        </tbody>
                      </table>
                    </div>
                }



              </div>
              {/* <div className='reg54'>54</div> */}
              <div className='reg55'><div className="directo" onClick={() => AddBetting('DIRECTO')}><input type="image" src={btnDirect} /></div></div>
              <div className='reg56'><div className="palante" onClick={() => AddBetting('PATRA Y PALANTE')}><input type="image" src={btnAhead} /></div></div>
              <div className='reg57'><div className="contodos" onClick={() => AddBetting('CON TODO')}><input type="image" src={all} /></div></div>
              <div className='reg58'><div className="repetir"><input className='rep' type="image" src={imgRepeat} onClick={() => EnterPay('repeat')} /></div></div>
              <div className='reg59'><div className="eliminar" onClick={Eliminar}><input type="image" src={imgDel} /></div></div>
              {/* <div className='reg60'></div> */}
              <div className='reg61'><div className="imprimir" onClick={ToPrint}><input type="image" src={imgPrint} /> </div></div>
              {/* <div className='reg62'>jkhyg</div>
                    <div className='reg63'></div> */}

            </div>
          </div>

        </div>
      )
    } else {
      return (
        <div className=''>
          <header>
            <nav>
              <div className="nvegador">
                <Link className='vol venta' to="/bet">VENTA</Link>
                <Link to="/line" className='vol lin'>LINEA</Link>
                <Link className='vol res' to='/history'>RESULTADOS</Link>
                <Link className='vol volvent' to='/venta'>VOLUMEN DE VENTAS</Link>
                <button className="boton_menu bg-danger" onClick={Logout}><DoorOpenFill /></button>
              </div>
            </nav>
          </header>
          <div className='suspendid'>
            <h1 className='carrAc'>Carrera Activa...</h1>
          </div>
        </div>
      )
    }
  }
}
export default Bet