import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { socket } from '../../io';
import Bet from '../bet/bet';
import './linea_responsive.css';

function Lines(number) {
    let line;
    const [lineForPaleMan, setLineForPaleMan] = useState();
    const [lineForPaleWoman, setLineForPaleWoman] = useState()
    const [goVenta, setGoVenta]= useState(false);
    localStorage.setItem('reload', true)
    const [result, setResult] = useState(false);
    const [lastWinerJpGral, setLastWinerJpGral] = useState('');
    const [winner, setWinner] = useState({ first: '', second: '', lineFirst: 0, linePale: 0 })
    const [time, setTime] = useState();
    const [jpg, setJpg] = useState();
    const [jpl, setJpl] = useState();
    const [p1, setP1] = useState();
    const [p2, setP2] = useState();
    const [p3, setP3] = useState();
    const [p4, setP4] = useState();
    const [p5, setP5] = useState();
    const [p6, setP6] = useState();
    const [p12, setP12] = useState();
    const [p13, setP13] = useState();
    const [p14, setP14] = useState();
    const [p15, setP15] = useState();
    const [p16, setP16] = useState();
    const [p21, setP21] = useState();
    const [p23, setP23] = useState();
    const [p24, setP24] = useState();
    const [p25, setP25] = useState();
    const [p26, setP26] = useState();
    const [p31, setP31] = useState();
    const [p32, setP32] = useState();
    const [p34, setP34] = useState();
    const [p35, setP35] = useState();
    const [p36, setP36] = useState();
    const [p41, setP41] = useState();
    const [p43, setP43] = useState();
    const [p42, setP42] = useState();
    const [p45, setP45] = useState();
    const [p46, setP46] = useState();
    const [p51, setP51] = useState();
    const [p52, setP52] = useState();
    const [p53, setP53] = useState();
    const [p54, setP54] = useState();
    const [p56, setP56] = useState();
    const [p61, setP61] = useState();
    const [p62, setP62] = useState();
    const [p63, setP63] = useState();
    const [p64, setP64] = useState();
    const [p65, setP65] = useState();
    const [lines, SetLines] = useState({});
    const [run, SetRun] = useState();
    const [code, SetCode] = useState();
    const [history, setHistory] = useState();
    const [man, setMan] = useState({ p1: 'num', p2: 'num', p3: 'num', p4: 'num', p5: 'num', p6: 'num' });
    const [lineUp, setLineUp] = useState(false);
  //  useEffect(() => {
        // socket.on('active', (data)=>{
            socket.emit('active-res',{point:localStorage.getItem('point')}) 
        // })
        socket.on('run', (runReq) => {
            SetRun(()=>runReq)
            if (run) if (run.run) if (run.run.code) SetCode(run.run.code.toString().padStart(4, '0'))
            if (run && run.history) setHistory(value => run.history != value? run.history: value)
            if(run && run.run && run.run.line) SetLines(()=> run.line)
            line = run? run.run? run.run.line:undefined:undefined
            if (line) {
                if(!lineUp){
                // Asignacion de stilo para macho y hembra
                const lineArray = [{ label: 'p1', value: line.d.p1 }, { label: 'p2', value: line.d.p2 }, { label: 'p3', value: line.d.p3 }, { label: 'p4', value: line.d.p4 }, { label: 'p5', value: line.d.p5 }, { label: 'p6', value: line.d.p6 }].sort((a, b) => a.value - b.value)
                const macho = lineArray[0].label;
                const woman = lineArray[5].label;
                const paleMan = `${lineArray[1].label}${lineArray[0].label.split('').at(-1)}`;
                const paleWoman = `${lineArray[5].label}${lineArray[4].label.split('').at(-1)}`;
                const linePaleArray = Object.keys(line)
                const linePaleMan = linePaleArray.filter(item => item === paleMan)[0];
                const linePaleWoman = linePaleArray.filter(item => item === paleWoman)[0];
               
                setLineForPaleMan(pale => pale != linePaleMan ? linePaleMan : pale)
                setLineForPaleWoman(pale => pale != linePaleWoman ? linePaleWoman : pale)
                // line = line.map(z => Object.keys(z)===paleMan? z+= `paleMan`: Object.keys(z)===paleWoman? z+=`paleHembra`:z)                
                setMan({ p1: 'num', p2: 'num', p3: 'num', p4: 'num', p5: 'num', p6: 'num' })
                setMan((value) => {
                    if (macho === 'p1') {
                        value.p1 = 'num macho'
                    } else if (macho === 'p2') {
                        value.p2 = 'num macho'
                    } else if (macho === 'p3') {
                        value.p3 = 'num macho'
                    } else if (macho === 'p4') {
                        value.p4 = 'num macho'
                    } else if (macho === 'p5') {
                        value.p5 = 'num macho'
                    } else if (macho === 'p6') {
                        value.p6 = 'num macho'
                    }
                    if (woman === 'p1') {
                        value.p1 = 'num hembra'
                    } else if (woman === 'p2') {
                        value.p2 = 'num hembra'
                    } else if (woman === 'p3') {
                        value.p3 = 'num hembra'
                    } else if (woman === 'p4') {
                        value.p4 = 'num hembra'
                    } else if (woman === 'p5') {
                        value.p5 = 'num hembra'
                    } else if (woman === 'p6') {
                        value.p6 = 'num hembra'
                    }
                    return value
                })

                // fin
                // Winner
                if (run && run.winnerThisRun && run.winnerThisRun.winners) {
                    setWinner(() => {
                        return {
                            first: run.winnerThisRun.winners.first,
                            second: run.winnerThisRun.winners.second,
                            lineFirst: run.winnerThisRun.lineFirst,
                            linePale: run.winnerThisRun.linePale
                        }
                    })
                } else {
                    // setWinner(() => {return { first: '', second: '', lineFirst: 0, linePale: 0 }})
                }
                //fin
                setP1(line && line.d && line.d.p1 ? line.d.p1.toFixed(1) : '')
                setP2(line && line.d && line.d.p2 ? line.d.p2.toFixed(1) : '')
                setP3(line && line.d && line.d.p3 ? line.d.p3.toFixed(1) : '')
                setP4(line && line.d && line.d.p4 ? line.d.p4.toFixed(1) : '')
                setP5(line && line.d && line.d.p5 ? line.d.p5.toFixed(1) : '')
                setP6(line && line.d && line.d.p6 ? line.d.p6.toFixed(1) : '')
                setP12(line.p12)
                setP13(line.p13)
                setP14(line.p14)
                setP15(line.p15)
                setP16(line.p16)
                setP21(line.p21)
                setP23(line.p23)
                setP24(line.p24)
                setP25(line.p25)
                setP26(line.p26)
                setP31(line.p31)
                setP32(line.p32)
                setP34(line.p34)
                setP35(line.p35)
                setP36(line.p36)
                setP41(line.p41)
                setP42(line.p42)
                setP43(line.p43)
                setP45(line.p45)
                setP46(line.p46)
                setP51(line.p51)
                setP52(line.p52)
                setP53(line.p53)
                setP54(line.p54)
                setP56(line.p56)
                setP61(line.p61)
                setP62(line.p62)
                setP63(line.p63)
                setP64(line.p64)
                setP65(line.p65)
                SetLines(line)
                setLineUp(true)
            }
            }else{
                setLineUp(false)  
            }
        
        })
 //   },[])
    socket.on("screen", data => {
        if (number.screen === 3) {
            if (result === true) setResult(() => false)
            if(localStorage.getItem('reload')==='false'){
                localStorage.setItem('reload', true)
                window.location.reload(); 
            }
        } else {
            if (result === false) setResult(() => true)
            if(localStorage.getItem('reload')==='true')  localStorage.setItem('reload', false)

        }
        if (data && data.jpg && jpg != data.jpg.value) {
            setJpg(data.jpg.value)
            setLastWinerJpGral(data.jpg.ticket)
            if (!jpl) {
                socket.emit('jpl', '')
                socket.on('jpl-from-server', (jp) => {
                    setJpl(jp)
                });
            }
        }
        if (data.time) {
            setTime(data.time)
        }
    });
    if (number.screen === 3 || number.screen === 1) {
        return (
            <div className="grilla">

                <div className="gri1"><p className="gr"></p></div>

                <div className="gri2">
                    <div className="carrera_num">
                        <div className="historial"><p>HISTORIAL</p></div>
                        <div className="tiulo_carrera"><p>CARRERA</p></div>
                        <div className="numero_carrera"><p>{code}</p></div>
                        <Link to='/bet'>
                        <div className="flecha">
                        </div></Link>

                    </div>
                </div>

                <div className="gri3">
                    <div className="corriendog">
                        <p className="corrien">
                           {localStorage.getItem('point').toLocaleUpperCase().includes('PAGADORA')? 'Pagadora':'Corrien'}
                        </p>
                        <p1 className="dog">
                            {localStorage.getItem('point').toLocaleUpperCase().includes('PAGADORA')? 'SPORT':'DOGS'}
                        </p1>
                    </div>
                </div>

                <div className="gri4">


                    <div className="progreso">
                        <progress value={time} max="240"></progress>
                    </div>


                    <div className='timepadre'>
                        <div className='time'>{time} s</div>
                    </div>

                </div>

                <div className="gri5">
                    <div className="cont_12">
                        <div className="botonz_2"><div className="hisFirst"><div className='flotante'><div><p className="bonusflot bonflot" hidden={history && history.length > 0 && history[0].factor > 1 ? false : true}><b>BONO</b></p></div><div><p className="bonusflot equflot" hidden={history && history.length > 0 && history[0].factor > 1 ? false : true}><b>{history && history.length > 0 && history[0].factor > 1 ? `X${history[0].factor}` : ''}</b></p></div></div><div className="tickHist">{history && history.length > 0 ? history[0].code.toString().padStart(4, '0') : ''}</div><div></div></div></div>
                        {/* <div className="botonz_1"><p className="numz_1">{history && history.length > 0? history[0].code.toString().padStart(4, '0') : ''}</p></div> */}
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length > 0 ? history[0].winner.fisrt : ''}`}>{history && history.length > 0 ? history[0].winner.fisrt : ''}</p></div>
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length > 0 ? history[0].winner.second : ''}`}>{history && history.length > 0 ? history[0].winner.second : ''}</p></div>

                        <div className="botonz_2"><div className="hisFirst"><div className='flotante'><div><p className="bonusflot bonflot" hidden={history && history.length > 1 && history[1].factor > 1 ? false : true}><b>BONO</b></p></div><div><p className="bonusflot equflot" hidden={history && history.length > 1 && history[1].factor > 1 ? false : true}><b>{history && history.length > 1 && history[1].factor > 1 ? `X${history[1].factor}` : ''}</b></p></div></div><div className="tickHist">{history && history.length > 1 ? history[1].code.toString().padStart(4, '0') : ''}</div><div></div></div></div>
                        {/* <div className="botonz_1"><p className="numz_1">{history && history.length > 0? history[1].code.toString().padStart(4, '0') : ''}</p></div> */}
                        <div className="botonz_1"><a className={`numz_11 historialPerro${history && history.length >= 1 ? history[1].winner.fisrt : ''}`}>{history && history.length >= 1 ? history[1].winner.fisrt : ''}</a></div>
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 1 ? history[1].winner.second : ''}`}>{history && history.length >= 1 ? history[1].winner.second : ''}</p></div>

                        <div className="botonz_2"><div className="hisFirst"><div className='flotante'><div><p className="bonusflot bonflot" hidden={history && history.length > 2 && history[2].factor > 1 ? false : true}><b>BONO</b></p></div><div><p className="bonusflot equflot" hidden={history && history.length > 2 && history[2].factor > 1 ? false : true}><b>{history && history.length > 2 && history[2].factor > 1 ? `X${history[2].factor}` : ''}</b></p></div></div><div className="tickHist">{history && history.length > 0 ? history[2].code.toString().padStart(4, '0') : ''}</div><div></div></div></div>
                        {/* <div className="botonz_1"><p className="numz_1">{history && history.length > 0? history[2].code.toString().padStart(4, '0') : ''}</p></div> */}
                        <div className="botonz_1"><a className={`numz_11 historialPerro${history && history.length >= 2 ? history[2].winner.fisrt : ''}`}>{history && history.length >= 2 ? history[2].winner.fisrt : ''}</a></div>
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 2 ? history[2].winner.second : ''}`}>{history && history.length >= 2 ? history[2].winner.second : ''}</p></div>

                        <div className="botonz_2"><div className="hisFirst"><div className='flotante'><div><p className="bonusflot bonflot" hidden={history && history.length > 1 && history[3].factor > 1 ? false : true}><b>BONO</b></p></div><div><p className="bonusflot equflot" hidden={history && history.length > 3 && history[3].factor > 1 ? false : true}><b>{history && history.length > 3 && history[3].factor > 1 ? `X${history[3].factor}` : ''}</b></p></div></div><div className="tickHist">{history && history.length > 0 ? history[3].code.toString().padStart(4, '0') : ''}</div><div></div></div></div>
                        {/* <div className="botonz_1"><p className="numz_1">{history && history.length > 0? history[3].code.toString().padStart(4, '0') : ''}</p></div> */}
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 3 ? history[3].winner.fisrt : ''}`}>{history && history.length >= 3 ? history[3].winner.fisrt : ''}</p></div>
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 3 ? history[3].winner.second : ''}`}>{history && history.length >= 3 ? history[3].winner.second : ''}</p></div>

                        <div className="botonz_2"><div className="hisFirst"><div className='flotante'><div><p className="bonusflot bonflot" hidden={history && history.length > 1 && history[4].factor > 1 ? false : true}><b>BONO</b></p></div><div><p className="bonusflot equflot" hidden={history && history.length > 4 && history[4].factor > 1 ? false : true}><b>{history && history.length > 4 && history[4].factor > 1 ? `X${history[4].factor}` : ''}</b></p></div></div><div className="tickHist">{history && history.length > 0 ? history[4].code.toString().padStart(4, '0') : ''}</div><div></div></div></div>
                        {/* <div className="botonz_1"><p className="numz_1">{history && history.length > 0? history[4].code.toString().padStart(4, '0') : ''}</p></div> */}
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 4 ? history[4].winner.fisrt : ''}`}>{history && history.length >= 4 ? history[4].winner.fisrt : ''}</p></div>
                        <div className="botonz_1"><a className={`numz_11 historialPerro${history && history.length >= 4 ? history[4].winner.second : ''}`}>{history && history.length >= 4 ? history[4].winner.second : ''}</a></div>

                        <div className="botonz_2"><div className="hisFirst"><div className='flotante'><div><p className="bonusflot bonflot" hidden={history && history.length > 1 && history[5].factor > 1 ? false : true}><b>BONO</b></p></div><div><p className="bonusflot equflot" hidden={history && history.length > 5 && history[5].factor > 1 ? false : true}><b>{history && history.length > 5 && history[5].factor > 1 ? `X${history[5].factor}` : ''}</b></p></div></div><div className="tickHist">{history && history.length > 0 ? history[5].code.toString().padStart(4, '0') : ''}</div><div></div></div></div>
                        {/* <div className="botonz_1"><p className="numz_1">{history && history.length > 0? history[5].code.toString().padStart(4, '0') : ''}</p></div> */}
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 5 ? history[5].winner.fisrt : ''}`}>{history && history.length >= 5 ? history[5].winner.fisrt : ''}</p></div>
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 5 ? history[5].winner.second : ''}`}>{history && history.length >= 5 ? history[5].winner.second : ''}</p></div>

                        <div className="botonz_2"><div className="hisFirst"><div className='flotante'><div><p className="bonusflot bonflot" hidden={history && history.length > 1 && history[6].factor > 1 ? false : true}><b>BONO</b></p></div><div><p className="bonusflot equflot" hidden={history && history.length > 6 && history[6].factor > 1 ? false : true}><b>{history && history.length > 6 && history[6].factor > 1 ? `X${history[6].factor}` : ''}</b></p></div></div><div className="tickHist">{history && history.length > 0 ? history[6].code.toString().padStart(4, '0') : ''}</div><div></div></div></div>
                        {/* <div className="botonz_1"><p className="numz_1">{history && history.length > 0? history[6].code.toString().padStart(4, '0') : ''}</p></div> */}
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 6 ? history[6].winner.fisrt : ''}`}>{history && history.length >= 6 ? history[6].winner.fisrt : ''}</p></div>
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 6 ? history[6].winner.second : ''}`}>{history && history.length >= 6 ? history[6].winner.second : ''}</p></div>

                        <div className="botonz_2"><div className="hisFirst"><div className='flotante'><div><p className="bonusflot bonflot" hidden={history && history.length > 1 && history[7].factor > 1 ? false : true}><b>BONO</b></p></div><div><p className="bonusflot equflot" hidden={history && history.length > 7 && history[7].factor > 1 ? false : true}><b>{history && history.length > 7 && history[7].factor > 1 ? `X${history[7].factor}` : ''}</b></p></div></div><div className="tickHist">{history && history.length > 0 ? history[7].code.toString().padStart(4, '0') : ''}</div><div></div></div></div>
                        {/* <div className="botonz_1"><p className="numz_1">{history && history.length > 0? history[7].code.toString().padStart(4, '0') : ''}</p></div> */}
                        <div className="botonz_1"><p className={`numz_11 historialPerro${history && history.length >= 7 ? history[7].winner.fisrt : ''}`}>{history && history.length >= 7 ? history[7].winner.fisrt : ''}</p></div>
                        <div className="botonz_1"><a className={`numz_11 historialPerro${history && history.length >= 7 ? history[7].winner.second : ''}`}>{history && history.length >= 7 ? history[7].winner.second : ''}</a></div>
                    </div>
                </div>

                <div className="gri6"><p className="gr"></p></div>

                <div className="gri7">

                    <div className="contenedor">
                        <div className="boton">
                            <div className='verticalStyle1 subStyle4'>
                                <div></div>
                                <div className='unoAside neutral'>GANADOR</div>
                                <div></div>
                                <div className='ordinalFirst ordinalfirst4'></div>
                                <div className='contDirEst'><div className='dirEst dirEst4 newDir'></div></div>
                                <div></div>
                                <div></div>
                            </div>


                        </div>
                        <div className="boton">
                            <div className='style1'>
                                <div className='ordinalSecond'>2do</div>
                                <div></div>
                                <div className='dosHeader'>1</div>
                                <div></div>
                                <div className='padreDirLeft'><div className='dirLeft'></div></div>
                            </div>
                        </div>
                        <div className="boton">
                            <div className='style1 subStyle2'>
                                <div className='ordinalSecond'>2do</div>
                                <div></div>
                                <div className='dosHeader'>2</div>
                                <div></div>
                                <div className='padreDirLeft'><div className='dirLeft'></div></div>
                            </div>
                        </div>
                        <div className="boton">
                            <div className='style1 subStyle3'>
                                <div className='ordinalSecond'>2do</div>
                                <div></div>
                                <div className='dosHeader tresheader'>3</div>
                                <div></div>
                                <div className='padreDirLeft'><div className='dirLeft'></div></div>
                            </div>
                        </div>
                        <div className="boton">
                            <div className='style1 subStyle4'>
                                <div className='ordinalSecond ordinalCuatro'>2do</div>
                                <div></div>
                                <div className='dosHeader'>4</div>
                                <div></div>
                                <div className='dirLeft4'><div className='dirLeft'></div></div>
                            </div>
                        </div>
                        <div className="boton">
                            <div className='style1 subStyle5'>
                                <div className='ordinalSecond'>2do</div>
                                <div></div>
                                <div className='dosHeader fiveHeader'>5</div>
                                <div></div>
                                <div className='padreDirLeft'><div className='dirLeft'></div></div>
                            </div>
                        </div>
                        <div className="boton">
                            <div className='style1 subStyle6'>
                                <div className='ordinalSecond ordinal6'>2do</div>
                                <div></div>
                                <div className='dosHeader seisHeader'>6</div>
                                <div></div>
                                <div className='dirLeft6'><div className='dirLeft'></div></div>
                            </div>
                        </div>
                        <div className="boton">
                            <div className='verticalStyle1'>
                                <div></div>
                                <div className='unoAside'>1</div>
                                <div></div>
                                <div className='ordinalFirst'>1er</div>
                                <div className='contDirEst'><div className='dirEst'></div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div className="boton"><p className={man.p1}>{p1}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p12'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p12'? 'paleHembra':''}`}>{p12}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p13'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p13'? 'paleHembra':''}`}>{p13}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p14'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p14'? 'paleHembra':''}`}>{p14}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p15'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p15'? 'paleHembra':''}`}>{p15}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='16'? 'paleMacho':  lineForPaleWoman && lineForPaleWoman ==='p16'? 'paleHembra':''}`}>{p16}</p></div>
                        
                        <div className="boton">
                            <div className='verticalStyle1 subStyle2'>
                                <div></div>
                                <div className='unoAside'>2</div>
                                <div></div>
                                <div className='ordinalFirst'>1er</div>
                                <div className='contDirEst'><div className='dirEst'></div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p21'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p21'? 'paleHembra':''}`}>{p21}</p></div>
                        <div className="boton"><p className={man.p2}>{p2}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p23'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p23'? 'paleHembra':''}`}>{p23}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p24'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p24'? 'paleHembra':''}`}>{p24}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p25'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p25'? 'paleHembra':''}`}>{p25}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p26'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p26'? 'paleHembra':''}`}>{p26}</p></div>
                        
                        <div className="boton">
                            <div className='verticalStyle1 subStyle3'>
                                <div></div>
                                <div className='unoAside tresheader'>3</div>
                                <div></div>
                                <div className='ordinalFirst'>1er</div>
                                <div className='contDirEst'><div className='dirEst'></div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p31'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p31'? 'paleHembra':''}`}>{p31}</p></div>
                        <div className="boton"><p className={`num ${lineForPaleMan && lineForPaleMan ==='p32'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p32'? 'paleHembra':''}`}>{p32}</p></div>
                        <div className="boton"><p className={man.p3}>{p3}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p34'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p34'? 'paleHembra':''}`}>{p34}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p35'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p35'? 'paleHembra':''}`}>{p35}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p36'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p36'? 'paleHembra':''}`}>{p36}</p></div>
                        <div className="boton">
                            <div className='verticalStyle1 subStyle4'>
                                <div></div>
                                <div className='unoAside'>4</div>
                                <div></div>
                                <div className='ordinalFirst ordinalfirst4'>1er</div>
                                <div className='contDirEst'><div className='dirEst dirEst4'></div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p41'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p41'? 'paleHembra':''}`}>{p41}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p42'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p42'? 'paleHembra':''}`}>{p42}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p43'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p43'? 'paleHembra':''}`}>{p43}</p></div>
                        <div className="boton"><p className={man.p4}>{p4}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p45'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p45'? 'paleHembra':''}`}>{p45}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p46'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p46'? 'paleHembra':''}`}>{p46}</p></div>
                        <div className="boton">
                            <div className='verticalStyle1 subStyle5'>
                                <div></div>
                                <div className='unoAside ordinalfirst5'>5</div>
                                <div></div>
                                <div className='ordinalFirst'>1er</div>
                                <div className='contDirEst'><div className='dirEst'></div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p51'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p51'? 'paleHembra':''}`}>{p51}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p52'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p52'? 'paleHembra':''}`}>{p52}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p53'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p53'? 'paleHembra':''}`}>{p53}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p54'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p54'? 'paleHembra':''}`}>{p54}</p></div>
                        <div className="boton"><p className={man.p5}>{p5}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p56'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p56'? 'paleHembra':''}`}>{p56}</p></div>
                        <div className="boton">
                            <div className='verticalStyle1 subStyle6'>
                                <div></div>
                                <div className='unoAside unoAside6'>6</div>
                                <div></div>
                                <div className='ordinalFirst ordinalFirst6'>1er</div>
                                <div className='contDirEst'><div className='dirEst dirEst6'></div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p61'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p61'? 'paleHembra':''}`}>{p61}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p62'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p62'? 'paleHembra':''}`}>{p62}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p63'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p63'? 'paleHembra':''}`}>{p63}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p64'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p64'? 'paleHembra':''}`}>{p64}</p></div>
                        <div className="boton"><p className={`num ${ lineForPaleMan && lineForPaleMan ==='p65'? 'paleMacho': lineForPaleWoman && lineForPaleWoman ==='p65'? 'paleHembra':''}`}>{p65}</p></div>
                        <div className="boton"><p className={man.p6}>{p6}</p></div>
                    </div>
                    
                </div>


                <div className="gri8">
                    <div className='winJack'>
                        <div className='cabeza headJack'>Tipo JackPot</div>
                        <div className='cabeza headTicket'>Ticket Ganador</div>
                        <div className='cuerpo JackGral'>General</div>
                        <div className='cuerpo winJackGral align-center text-center'>{lastWinerJpGral===0? '----':lastWinerJpGral}</div>
                        <div className='cuerpo Jacklocal align-center text-center'>Local</div>
                        <div className='cuerpo winJacklocal align-center text-center'>{jpl ? jpl.ticket===0?'----':jpl.ticket:'----'}</div>
                    </div>
                </div>

                <div className="gri9">
                    <div className="jackpots">
                        <div className="numgral"><p className="jackpot_local">jackpot Local</p></div>
                        <div className="numgral"><p className="conteo_local">{jpl?parseFloat(jpl.value).toFixed(2):'0'}</p></div>
                        <div className="numgral"><p className="jackpot_general">Jackpot General</p></div>
                        <div className="numgral"><p className="conteo_general">{parseFloat(jpg).toFixed(2)}</p></div>
                    </div>
                </div>


                <div className='resultados' hidden={result}>
                    <div className='gridResult'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>

                        <div></div>
                        <div></div>
                        <div className='winGanador'><p className='winnerTitle'>GANADOR</p></div>
                        <div></div>
                        <div></div>

                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>

                        <div></div>

                        {/*perro #1 ganador en Primero*/}
                        <div className={`winprimero_${winner.first}`}>
                            <div className='contWin'>
                                <div className={`iniWin${winner.first}`}>1er</div>
                                <div className='centerWin'><div className={`flechaUp${winner.first}`}></div><div className={`flechaDown${winner.first}`}></div></div>
                                <div className={`win${winner.first}`}>{winner.first}</div>
                            </div>
                        </div>


                        <div></div>
                        <div><p className='linePrimerLugar'>{winner.lineFirst.toFixed(2)}</p></div>
                        <div></div>

                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>


                        <div></div>

                        {/*_____Pale, perro #1 ganador en Primero*/}
                        <div className={`winPale${winner.first}`}>
                            <div className="contWinPale">
                                <div className={`iniGanador${winner.first}`}>1er</div>
                                <div className='medioWin'><div className={`arrowUp${winner.first}`}></div><div className={`arrowDown${winner.first}`}></div></div>
                                <div className={`ganadorPale${winner.first}`}>{winner.first}</div>
                            </div>
                        </div>


                        <div></div>

                        {/*Segundo Lugar*/}

                        {/*_____Pale, perro ganador en Segundo*/}
                        <div className={`winPale${winner.second}`}>
                            <div className="contWinPale">
                                <div className={`iniGanador${winner.second}`}>2do</div>
                                <div className='medioWin'><div className={`arrowUp${winner.second}`}></div><div className={`arrowDown${winner.second}`}></div></div>
                                <div className={`ganadorPale${winner.second}`}>{winner.second}</div>
                            </div>
                        </div>

                        <div></div>
                        <div className='paleLineaSegundo'><p className='linePaleSegundo'>{winner.linePale}</p></div>
                        <div></div>

                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>

                    </div>
                </div>
            </div>
        )

    } else {
        return (
            <div className='error'>Error</div>
        )
    }
}

export default Lines